import React from "react";
import Template from "../../components/SignInPageComponents/Template";
const Signup = () => {
  return (
    <Template
      title="Create your free account"
      description="Or continue with email"
      formType="signup"
    />

  );
};

export default Signup;
