import React from 'react';
import { RiWifiOffLine } from "react-icons/ri";
import { useSelector } from 'react-redux';

const InternetConnectionIssue = () => {
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);

  const isWelcomePage = location.pathname.includes('/welcome-page');

  const handleTryAgain = () => {
    if (navigator.onLine) {
      window.location.reload();
    } else {
      alert('Still offline. Please check your connection.');
    }
  };

  return (
    <div className={`fixed inset-0 bg-[#FFFFFF] font-dmsans flex flex-col items-center justify-center p-4 text-center ${!isWelcomePage && (isSidebarOpen ? 'lg:ml-[180px]' : 'lg:ml-[40px]')}`}>
      <RiWifiOffLine className="text-red-500 text-6xl mb-4" />
      <h1 className="text-3xl font-semibold mb-2">Ooops!</h1>
      <p className="mb-4 text-[18px]"> No internet connection found. Please check your connection.</p>
      <button
        className="px-4 py-2 bg-[#96F3C3] text-[#2B2E3A] rounded-md hover:bg-gray-200"
        onClick={handleTryAgain}
      >
        Try again
      </button>
    </div>
  );
};

export default InternetConnectionIssue;
