import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import GoogleSignInButton from "./GoogleSigninButton";
import { toast } from "react-toastify";
import axios from "axios";
import { getStoredValue } from "../../services/logics";
import { LuArrowLeft } from "react-icons/lu";
import SigninForm from "./SigninForm";
import SignupForm from "./SignupForm";

const Template = ({ title, description, formType, children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [inviteId, setInviteId] = useState(null);
    const API_URL = process.env.REACT_APP_API_URL;
    const isLoggedIn = getStoredValue("isLoggedIn");
    const [inviteApiFailed, setInviteApiFailed] = useState(false); // State to track API failure
    const isForgotPasswordPage = location.pathname.includes("/forgot-password");
    const isResetPasswordPage = location.pathname.includes("/reset-password");
    const isVerifyEmailPage = location.pathname.includes("/verify-email");
    const [backgroundImage, setBackgroundImage] = useState(null);

    useEffect(() => {
        const loadImage = async () => {
            try {
                const importedImage = await import('../../assets/DraftBoardImage.svg');
                setBackgroundImage(importedImage.default);
            } catch (error) {
                console.error('Failed to load the image:', error);
            }
        };

        loadImage();
    }, []);

    useEffect(() => {
        const checkAuthAndInvite = async () => {
            // Extract token and invite_id from URL parameters
            const urlParams = new URLSearchParams(window.location.search);
            const tokenFromUrl = urlParams.get('urltoken');
            const inviteIdParam = urlParams.get('invite_id');

            if (tokenFromUrl) {
                // Clear previous session data and store the new token
                sessionStorage.clear();
                sessionStorage.setItem("token", tokenFromUrl);
                sessionStorage.setItem("email", urlParams.get('email'));
                sessionStorage.setItem("expiry_in", urlParams.get('expiry_in'));
                sessionStorage.setItem("is_super_admin", urlParams.get('is_super_admin'));
                // Always navigate to the document page when token is present in the URL
                navigate("/document-page", { replace: true });
            } else {
                // Check if the user has a valid token
                const token = getStoredValue("token");
                sessionStorage.setItem("token", token);

                // If token exists and user is already logged in
                if (token && isLoggedIn === "true") {
                    try {
                        const isSuperAdmin = getStoredValue("is_super_admin");
                        const store_id = getStoredValue("storeid");

                        // Redirect based on the user's role
                        if (isSuperAdmin && (isSuperAdmin?.toLowerCase() === "true" && (store_id === "undefined"))) {
                            navigate("/admin/dashboard-page", { replace: true });
                        } else {
                            navigate("/", { replace: true });
                        }
                    } catch (error) {
                        console.error("Error during authentication check:", error);
                    }
                }

                // If no token from URL, check if invite_id is present and process invitation
                if (inviteIdParam) {
                    setInviteId(inviteIdParam);
                    const postInviteId = async () => {
                        try {
                            const details = {
                                invite_id: String(inviteIdParam)
                            };
                            const response = await axios.post(`${API_URL}/v1/invites/accept`, details);
                            toast.success("Invitation accepted!");
                            setInviteApiFailed(false);
                            navigate('/signin', { replace: true });
                        } catch (error) {
                            setInviteApiFailed(true); // Set API failure state
                            toast.error(`${error.response?.data?.detail || error.response?.data?.error || 'An unexpected error occurred'}`);
                        }
                    };
                    // hasInvitedPosted.current = true;
                    postInviteId();
                }
            }
        };
        checkAuthAndInvite();
    }, [navigate, API_URL]);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === "isLoggedIn" && event.newValue === "true") {
                // Check if sessionStorage is already populated
                const token = sessionStorage.getItem("token");
                const localtoken = localStorage.getItem("token");
                const isSuperAdmin = getStoredValue("is_super_admin");
                const store_id = getStoredValue("storeid");
                sessionStorage.setItem("token", localtoken);

                // Redirect based on the user's role
                if (isSuperAdmin && (isSuperAdmin?.toLowerCase() === "true" && (store_id === "undefined"))) {
                    navigate("/admin/dashboard-page", { replace: true });
                } else {
                    navigate("/", { replace: true });
                }
            }
        };

        window.addEventListener("storage", handleStorageChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [navigate]);  // Include navigate in the dependency array


    const navigateToHome = () => {
        window.location.href = "https://www.zenva-ai.com/"; // Redirect to the URL
    };


    return (
        <section
            className="h-screen grid grid-cols-1 lg:grid-cols-2 font-dmsans overflow-y-auto bg-cover bg-center bg-black"
            style={{
                backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
                backgroundSize: 'cover',
                backgroundPosition: backgroundImage ? 'left center' : 'center',
                backgroundColor: !backgroundImage ? '#000' : 'transparent', // Fallback to black
            }}
        >
            {/* Left Section - Background Placeholder */}
            <div className="hidden lg:block"></div>

            {/* Right Section */}
            <div
                className="my-10 lg:w-[500px] ml-auto mr-[40px] h-auto flex flex-col justify-center rounded-md"
                style={{
                    border: '0.1px solid',
                    borderImageSource: `linear-gradient(139.21deg, rgba(77, 255, 177, 0.5) -6.51%, rgba(65, 62, 62, 0.5) 100.91%), linear-gradient(143.57deg, rgba(89, 86, 86, 0.1) -0.7%, rgba(57, 246, 163, 0.1) 112.01%)`,
                    backdropFilter: 'blur(25px)',
                    boxShadow: '0px 4px 4px 0px #00000040',
                }}
            >
                <button
                    onClick={navigateToHome}
                    className="gap-1 flex items-center justify-center bg-[#FFFFFF0D] w-[80px] h-[30px] border border-[#FAFAFA99] rounded-md text-[#E4E4E4] hover:bg-gray-700 transition-colors font-semibold text-[12px] m-2"
                >
                    <span>
                        <LuArrowLeft size={16} />
                    </span>
                    Home
                </button>
                {
                    (isForgotPasswordPage || isResetPasswordPage || isVerifyEmailPage) ? (
                        <>
                            {/* Render children */}
                            {children}
                        </>
                    ) : (
                        <div className="flex flex-col justify-center items-start h-full lg:w-[418px] mx-auto py-5 ">
                            {/* Welcome Message */}
                            <h1 className="text-[#FFFFFF] text-[24px] lg:text-[30px] font-semibold mb-[10px]">
                                {title}
                            </h1>

                            {/* Subheading */}
                            <p className="text-[#ABABAB] text-[18px] mb-[14px] font-light">
                                Connect to Zenva with
                            </p>

                            {formType === "signup" ? <SignupForm /> : <SigninForm />}

                            <div className="flex items-center w-full lg:w-[418px] my-6">
                                <div className="flex-grow h-px bg-[#ABABAB]"></div>
                                <span className="px-2 text-[#ABABAB] text-[12px] font-medium">
                                    {description}
                                </span>
                                <div className="flex-grow h-px bg-[#ABABAB]"></div>
                            </div>

                            {/* Google Sign-In Button */}
                            <GoogleSignInButton inviteId={inviteId} isDisabled={!!inviteId && inviteApiFailed} />

                            {formType === "signin" && <div className="flex text-[#DBDBDB] my-3 text-[18px] font-dmsans">
                                <span className="font-normal ">Don't have an account?</span>
                                <Link to="/signup" className="ml-1 text-[#337EFF] font-normal">
                                    Sign up here
                                </Link>
                            </div>}
                            {formType === "signup" &&
                                <>

                                    <div className="text-[#8E8E8E] text-[14px] font-normal my-3">
                                        By creating an account, you agree to our {" "}
                                        <span className="text-[#337EFF] cursor-pointer">
                                            <a href="https://www.zenva-ai.com/terms-conditions" target="_blank" rel="noopener noreferrer">
                                                terms & conditions
                                            </a> </span>and{" "}
                                        <span className="text-[#337EFF] cursor-pointer">
                                            <a href="https://www.servicepoints.eu/en/legal/privacy-policy" target="_blank" rel="noopener noreferrer">
                                                privacy policy.
                                            </a> </span>
                                    </div>

                                    <div className="flex text-[#DBDBDB] text-[18px] font-dmsans">
                                        <span className="font-normal ">Already have an account?</span>
                                        <Link to="/signin" className="ml-1 text-[#337EFF] font-normal">
                                            Log in
                                        </Link>
                                    </div>
                                </>}
                        </div>
                    )}
            </div>
        </section>


    );
};

export default Template;
