import React, { useEffect } from 'react';
import { Outlet,useLocation } from 'react-router-dom';
import AdminSidebar from '../components/AdminSidebar';
import Navbar from '../pages/Navbar';
import { useNetworkStatus } from "../services/logics";
import InternetConnectionIssue from "../components/InternetConnectionIssue";
const AdminLayout = () => {
  const location = useLocation();
  const { isOnline, updateNetworkStatus } = useNetworkStatus();
  useEffect(() => {
    updateNetworkStatus();
  }, [location]);  
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'isLoggedIn' && event.newValue === 'false') {
        // Log out this tab if isLoggedIn is set to false
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/signin';
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  return (
    <> <Navbar />
    <div className="flex">
      <AdminSidebar />
      {isOnline ? (
      <div className="flex-3  w-full h-full">
        <Outlet />
      </div>):(<InternetConnectionIssue/>)}
    </div>
    </>
  );
};

export default AdminLayout;