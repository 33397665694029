import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    documents: false,
    integration: false,
    isOpen: false,
  },
  reducers: {

    // Sets documents to true
    setDocumentsTrue: (state) => {
      state.documents = true;
    },
    // Sets documents to false
    setDocumentsFalse: (state) => {
      state.documents = false;
    },
    // Sets integration to true
    setIntegrationTrue: (state) => {
      state.integration = true;
    },
    // Sets integration to false
    setIntegrationFalse: (state) => {
      state.integration = false;
    },
    openNotification: (state) => {
      state.isOpen = true;
    },
    closeNotification: (state) => {
      state.isOpen = false;
    },
  },
});

export const { 
  setDocumentsTrue, 
  setDocumentsFalse, 
  setIntegrationTrue, 
  setIntegrationFalse ,
  openNotification,
  closeNotification
} = notificationSlice.actions;

export default notificationSlice.reducer;
