import { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import axios from "axios";
import Template from "../../components/SignInPageComponents/Template";
import { toast } from "react-toastify";
import LoadingAnimation from "../../assets/LoadingAnimation.svg";

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;


    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (!email) {
            toast.error("Email is required.");
            return;
        }

        try {
            setLoading(true);

            // Create FormData object
            const formData = new FormData();
            formData.append("email", email);

            // Make API request
            const response = await axios.post(`${API_URL}/v1/auth/forgot-password`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setEmailSent(true);
            toast.success(response.data.message || "Password reset link sent!");
        } catch (error) {
            const errorMessage = error.response?.data?.detail || "An error occurred while sending the email.";
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Template>
            <div className="flex flex-col justify-center items-start lg:w-[418px] mt-4 mx-auto space-y-6 font-dmsans">
                <h1 className="font-semibold text-[#FFFFFF] lg:text-[30px]">
                    {!emailSent ? "Reset your password" : "Check your email"}
                </h1>
                <p className="text-[18px] text-[#ABABAB] font-light">
                    {!emailSent
                        ? "Enter your email address and we will send you a password reset link."
                        : `We have sent the reset email to ${email}.`}
                </p>
                <form onSubmit={handleOnSubmit} className="w-full space-y-6">
                    {!emailSent && (
                        <label className="mt-1 w-full">
                            <p className="text-[14px] font-normal text-[#DBDBDB]">Email</p>
                            <input
                                required
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter email address"
                                autoComplete="off"
                                className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] text-[18px] font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px] mt-1"
                            />
                        </label>
                    )}
                    <button
                        type="submit"
                        disabled={loading}
                        className={`w-full rounded-[6px] h-[50px] text-[18px] font-semibold flex justify-center items-center ${loading ? "bg-[#8E8E8E] text-[#FFFFFF]" : "bg-[#F3F3F3] text-[#1B1A22]"
                            }`}
                    >
                        {loading ? (
                            <span className="flex justify-center items-center h-full w-12 lg:w-14">
                                <img src={LoadingAnimation} alt="Loading..." />
                            </span>
                        ) : emailSent ? (
                            "Resend email"
                        ) : (
                            "Send password reset link"
                        )}
                    </button>


                </form>
                <div className="flex items-center justify-between text-[#DBDBDB] text-[18px] font-dmsans mt-3">
                    <Link to="/signin">
                        <p className="flex items-center gap-x-2">
                            <BiArrowBack /> Back to login
                        </p>
                    </Link>
                </div>
            </div>
        </Template>
    );
}

export default ForgotPassword;
