import { useEffect, useState } from "react";
import axiosInstance from "./axiosInstance";

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const formatTime = (dateString) => {
  const date = new Date(dateString);
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC'
  });
  return formattedTime;
};

export const formatDay = (dateString) => {
  const date = new Date(dateString);
  const formattedDay = date.toLocaleString('en-US', {
    weekday: 'short',
    timeZone: 'UTC'
  });
  return formattedDay;
};

export const formatCustomDateTime = (dateString, fullYear = true) => {
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const month = date.toLocaleString('en-US', {
    month: 'long',
    timeZone: 'UTC'
  });
  const year = fullYear ? date.getUTCFullYear() : date.getUTCFullYear().toString().slice(-2); // Conditionally format year
  const time = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'UTC'
  })?.toLowerCase();

  if (fullYear) {
    return `${month} ${day}, ${year}`;
  } else {
    return `${day} ${month} '${year}, ${time}`;
  }
};

export const formatCustomISO = (date) => {
  // Get the components of the date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = '00';
  const minutes = '00';
  const seconds = '00';
  const milliseconds = '000';
  const microseconds = '850';

  // Combine into the desired format
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${microseconds}`;
};

export const getStoredValue = (key) => {
  return sessionStorage.getItem(key) || localStorage.getItem(key);
};

export const setInBothStorages = (key, value) => {
  [localStorage, sessionStorage].forEach((storage) => {
    storage.setItem(key, value);
  });
};

export const useNetworkStatus = () => {
  const [isOnline, setOnline] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;

  const updateNetworkStatus = async () => {
    try {
      if (!navigator.onLine) {
        setOnline(false);
        return;
      }
      const response = await axiosInstance.get(`${API_URL}/v1/auth/check-internet`);
      setOnline(response?.status === 200);
    } catch (error) {
      setOnline(false);
    }
  };

  return { isOnline, updateNetworkStatus };
};
