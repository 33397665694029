import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { withDevCycleProvider } from "@devcycle/react-client-sdk";

// Non-lazy routes for critical UI that needs to load instantly
import Signin from "./pages/AuthPages/Signin";
import Signup from "./pages/AuthPages/Signup"
import ForgotPassword from "./pages/AuthPages/ForgotPassword"
import ResetPassword from "./pages/AuthPages/ResetPassword"
import VerifyEmail from "./pages/AuthPages/VerifyEmail";
import Layout from "./layouts/ContentLayout";
import DocumentsPage from "./pages/DocumentsPage";
import PrivateRoute from "./layouts/PrivateLayout";
import AdminLayout from "./layouts/AdminLayout";
import ProtectedAdminRoutes from "./layouts/ProtectedAdminRoutes";

// Lazy-loaded routes for better performance and optimized initial load
const Home = lazy(() => import("./pages/Home"));
const DraftPage = lazy(() => import("./pages/DraftPage"));
const IntegrationPage = lazy(() => import("./pages/IntegrationPage"));
const ExamplesPage = lazy(() => import("./pages/ExamplesPage"));
const ExampleDetailPage = lazy(() => import("./pages/ExamplePages/ExampleDetailPage"));
const CreateExamplesPage = lazy(() => import("./pages/ExamplePages/CreateExamplesPage"));
const AccountPage = lazy(() => import("./pages/AccountPage"));
const DraftDetailPage = lazy(() => import("./pages/DraftDetailPage"));
const ApplicationSetup = lazy(() => import("./pages/ApplicationSetup"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const AdminHome = lazy(() => import("./pages/AdminPages/AdminHome"));
const AdminDashboard = lazy(() => import("./pages/AdminPages/AdminDashboard"));
const AdminStorePage = lazy(() => import("./pages/AdminPages/AdminStorePage"));
const AdminStorePageDetails = lazy(() => import("./pages/AdminPages/AdminStorePageDetails"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const CreatePolicyPage = lazy(() => import("./pages/CreatePolicyPage"));
const Error502Page = lazy(() => import("./pages/Error502Page"));

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/" element={<PrivateRoute isAdminRoute={false} />}>
            <Route element={<Layout />}>
              <Route index element={
                <Suspense fallback={<div></div>}>
                  <Home />
                </Suspense>

              } />
              <Route
                path="/emails-page"
                element={
                  <Suspense fallback={<div></div>}>
                    <DraftPage />
                  </Suspense>
                }
              />
              <Route
                path="/email-page/detail/:id"
                element={
                  <Suspense fallback={<div></div>}>
                    <DraftDetailPage />
                  </Suspense>
                }
              />
              <Route path="/document-page" element={<DocumentsPage />} />
              <Route
                path="/document-page/create-policy/:id?"
                element={
                  <Suspense fallback={<div></div>}>
                    <CreatePolicyPage />
                  </Suspense>
                }
              />
              <Route
                path="/integration-page"
                element={
                  <Suspense fallback={<div></div>}>
                    <IntegrationPage />
                  </Suspense>
                }
              />
              <Route
                path="/examples-page"
                element={
                  <Suspense fallback={<div></div>}>
                    <ExamplesPage />
                  </Suspense>
                }
              />
              <Route
                path="/create-example/:id?"
                element={
                  <Suspense fallback={<div></div>}>
                    <CreateExamplesPage />
                  </Suspense>
                }
              />
              <Route
                path="/example/:id?"
                element={
                  <Suspense fallback={<div></div>}>
                    <ExampleDetailPage />
                  </Suspense>
                }
              />
              <Route
                path="/accounts-page"
                element={
                  <Suspense fallback={<div></div>}>
                    <AccountPage />
                  </Suspense>
                }
              />
              <Route
                path="/welcome-page/:status?"
                element={
                  <Suspense fallback={<div></div>}>
                    <ApplicationSetup />
                  </Suspense>
                }
              />
            </Route>
          </Route>
          <Route path="/admin" element={<ProtectedAdminRoutes />}>
            <Route element={<AdminLayout />}>
              <Route path="" element={
                <Suspense fallback={<div></div>}>
                  <AdminHome />
                </Suspense>

              } />
              <Route
                path="dashboard-page"
                element={
                  <Suspense fallback={<div></div>}>
                    <AdminDashboard />
                  </Suspense>
                }
              />
              <Route
                path="store-page"
                element={
                  <Suspense fallback={<div></div>}>
                    <AdminStorePage />
                  </Suspense>
                }
              />
              <Route
                path="store-page/details/:id"
                element={
                  <Suspense fallback={<div></div>}>
                    <AdminStorePageDetails />
                  </Suspense>
                }
              />
            </Route>
          </Route>
          <Route
            path="/502-error"
            element={
              <Suspense fallback={<div></div>}>
                <Error502Page />
              </Suspense>
            }
          />
          <Route
            path="/error"
            element={
              <Suspense fallback={<div></div>}>
                <ErrorPage />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<div></div>}>
                <PageNotFound />
              </Suspense>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default withDevCycleProvider({
  sdkKey: process.env.REACT_APP_DEV_CYCLE_SDK_KEY,
  options: {
    enableEdgeDB: true, // Use EdgeDB for faster flag fetching
  },
})(App);