import React, { useEffect,useRef } from 'react';
import { CgFileDocument } from 'react-icons/cg';
import { MdOutlineArticle } from "react-icons/md";
import { MdIntegrationInstructions } from 'react-icons/md';
import { RiAccountCircleFill } from "react-icons/ri";
import { LuFolderEdit,LuMail,LuMailOpen,LuSend } from 'react-icons/lu';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../redux/sidebarSlice';
import MailLogo from "../assets/SidebarAssets/MailLogo.svg";
import { LuChevronRightCircle, LuChevronLeftCircle } from "react-icons/lu";
import {BREAKPOINTS} from "../services/emailUtils"
import { AiOutlineClose } from "react-icons/ai";
import {getStoredValue } from "../services/logics";
import ZenvaLogo from "../assets/SidebarAssets/ZenvaLogo.svg";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const {isOpen} = useSelector((state) => state.notification);
  const isActive = (path) => location.pathname === path;
  const isMobileScreen = window.matchMedia(`${BREAKPOINTS.mobile}, ${BREAKPOINTS.mobileLarge}, ${BREAKPOINTS.tablet}`).matches
  const sidebarRef = useRef(null);
  const is_super_admin = getStoredValue("is_super_admin")
  const storeid = getStoredValue("storeid")
  const isSuperadminStore=(is_super_admin === "True" && storeid !== "undefined")

  // useEffect(() => {
  //   dispatch(openSidebar());
  // }, [dispatch]);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };
  const handleLinkClick = () => {
    // Check if the screen matches the mobile breakpoint
    if (isMobileScreen) {
      handleToggleSidebar();
    }
  };
  useEffect(() => {
    if (isSidebarOpen && isMobileScreen) {
      const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          dispatch(toggleSidebar());
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isSidebarOpen, isMobileScreen, dispatch]);

  const textStyles = "text-base font-semibold";

  const getPaddingTop = () => {
    if (location.pathname.includes("document")) {
      // Apply document-specific padding rules
      if (isOpen && isSuperadminStore) {
        return 'pt-[60px] md:pt-[90px]';
      } else if (isOpen) {
        return 'pt-[10px] md:pt-[40px]';
      } else if (isSuperadminStore) {
        return 'pt-[20px] md:pt-[60px]';
      } else {
        return 'pt-[10px] md:pt-[24px]';
      }
    } else {
      // Apply padding only based on isSuperadminStore for other pages
      return isSuperadminStore ? 'pt-[20px] md:pt-[60px]' : 'pt-[10px] md:pt-[24px]';
    }
  };
  return (
    <>
    {/* Sidebar overlay */}
    {isSidebarOpen && isMobileScreen && (
      <div
        className="fixed inset-0  bg-black opacity-20 backdrop-blur-sm z-20"
        onClick={handleToggleSidebar} // Clicking outside (on overlay) closes sidebar
      />
    )}
    <div ref={sidebarRef} className={`fixed h-screen top-0 z-30 lg:z-10 ${isSidebarOpen ? 'lg:w-[216px] xl:w-[236px] w-[220px]' : 'w-0 lg:w-[80px] lg:block hidden'} bg-[#F3F3F3] text-white transition-all ease-in-out duration-300 flex flex-col justify-between  lg:flex-shrink-0`}
      style={{
        background: 'linear-gradient(176.62deg, #0B0C16 39.26%, #184139 92.26%)',
        border: '1.15px solid',
        borderImageSource: 'linear-gradient(180deg, #0E151C 0%, #21905F 100%)',
      }}
    >
      {/* Navigation and Menu */}
      <div>
        <div className="lg:hidden flex justify-end mt-[14px] mr-1">
          <button className="text-[#2B2E3A] items-center" onClick={handleToggleSidebar}>
            <AiOutlineClose size={16} className="cursor-pointer text-[#D7FDED] hover:text-gray-400" />
          </button>
        </div>
        <div className={`${getPaddingTop()} mb-[30px] lg:mb-[50px] pl-[22px]`}>
          <div
            className={`flex items-center pt-[4px]  mr-2 justify-start w-full`}
          >
            <img src={isSidebarOpen ?MailLogo:ZenvaLogo} alt="" className="h-[30px] w-auto" />
              {/* <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-lg font-semibold pb-2`}>Service Points</span> */}
          </div>        </div>

        <ul className="lg:space-y-3 space-y-8">
          <li className={`${isActive('/document-page') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
            <Link
              to="/document-page"
              className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/document-page') ? 'bg-[#39F6A333] bg-opacity-10 rounded-full text-[#39F6A3] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}
              onClick={handleLinkClick}
            >
              <MdOutlineArticle size={20} />
              <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Policies</span>
            </Link>
          </li>
          <li className={`${isActive('/emails-page') || location.pathname.startsWith('/email-page/detail') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
            <Link
              to="/emails-page?filter=drafts"
              className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/emails-page') || location.pathname.startsWith('/email-page/detail') ? 'bg-[#39F6A333] bg-opacity-10 rounded-full text-[#39F6A3] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}
              onClick={handleLinkClick}
            >
              <LuFolderEdit size={20} />
              <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Emails</span>
            </Link>
          </li>
            {/* Dropdown Menu */}
            {(isActive('/emails-page') || location.pathname.startsWith('/email-page/detail'))&& (
              <ul className={`ml-8 mt-2 space-y-2 text-[14px] border-l border-[#868CA2C4] pl-3 ${isSidebarOpen ? 'block' : 'hidden'} `}>
                <li className="group flex items-center cursor-pointer pt-1">
                  <Link
                    to="/emails-page?filter=drafts"
                    className={`flex items-center h-[32px] space-x-2 px-3 rounded-md w-full mr-4  ${location.search.includes('filter=drafts')
                        ? 'font-semibold text-[#FFFFFF] bg-[#FFFFFF29]'
                        : 'hover:bg-gray-600 text-[#868CA2] font-medium'
                      }`}
                  >
                    <LuMailOpen className="mr-1" />
                    <span>Drafts</span>
                  </Link>
                </li>
                <li className="group flex items-center cursor-pointer">
                  <Link
                    to="/emails-page?filter=all"
                    className={`flex items-center h-[32px] px-3 space-x-2  rounded-md w-full mr-4 ${location.search.includes('filter=all')
                        ? 'font-semibold text-[#FFFFFF] bg-[#FFFFFF29]'
                        : 'hover:bg-gray-600 text-[#868CA2] font-medium'
                      }`}
                  >
                    <LuMail className="mr-1" />
                    <span>All</span>
                  </Link>
                </li>
                <li className="group flex items-center cursor-pointer">
                  <Link
                    to="/emails-page?filter=sent"
                    className={`flex items-center h-[32px] space-x-2  px-3 rounded-md w-full mr-4 ${location.search.includes('filter=sent')
                        ? 'font-semibold text-[#FFFFFF] bg-[#FFFFFF29]'
                        : 'hover:bg-gray-600 text-[#868CA2] font-medium'
                      }`}
                  >
                    <LuSend className="mr-1" />
                    <span>Sent</span>
                  </Link>
                </li>
              </ul>
            )}
          <li className={`${isActive('/integration-page') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
            <Link
              to="/integration-page"
              className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/integration-page') ? 'bg-[#39F6A333] bg-opacity-10 rounded-full text-[#39F6A3] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}
              onClick={handleLinkClick}
            >
              <MdIntegrationInstructions size={20} />
              <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Integrations</span>
            </Link>
          </li>
          <li className={`${isActive('/examples-page') || location.pathname.startsWith('/example') || location.pathname.startsWith('/create-example') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
            <Link
              to="/examples-page"
              className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/examples-page') || location.pathname.startsWith('/example') || location.pathname.startsWith('/create-example') ? 'bg-[#39F6A333] bg-opacity-10 rounded-full text-[#39F6A3] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}
              onClick={handleLinkClick}
            >
              <CgFileDocument size={20} />
              <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Examples</span>
            </Link>
          </li>

          <li className={`${isActive('/accounts-page') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
            <Link
              to="/accounts-page"
              className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/accounts-page') ? 'bg-[#39F6A333]  bg-opacity-10 rounded-full text-[#39F6A3] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}
              onClick={handleLinkClick}
            >
              <RiAccountCircleFill size={20} />
              <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Accounts</span>
            </Link>
          </li>
        </ul>
      </div>

      <div className="lg:flex lg:flex-grow lg:items-end hidden">
        <div className={`absolute bottom-4 ${isSidebarOpen ? 'right-5' : 'right-1/2 transform translate-x-1/2'}`}>
          {isSidebarOpen ? (
            <LuChevronLeftCircle
              size={38}
              className="cursor-pointer text-[#D7FDED] hover:text-gray-400"
              style={{ strokeWidth: 0.6 }} // Adjust stroke width here
              onClick={handleToggleSidebar}
            />
          ) : (
            <LuChevronRightCircle
              size={38}
              className="cursor-pointer text-[#D7FDED] hover:text-gray-400"
              style={{ strokeWidth: 0.6 }} // Adjust stroke width here
              onClick={handleToggleSidebar}
            />
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default Sidebar;
