import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isAuthenticated } from "../services/login";
import { getStoredValue } from "../services/logics";
import { toast } from "react-toastify";
import RotatingLoader from "../assets/RotatingLoader.jsx"
// Redirect component to handle toast and navigation
const RedirectToDocumentPage = () => {
  useEffect(() => {
    toast.info("To proceed, please upload a Document. This step is necessary for draft generation.", {
      toastId:'document-upload-info',
    });  }, []);

  return <Navigate to="/document-page" replace />;
};
const RedirectToIntegrationPage = ({getSetupReminderMessage}) => {
  useEffect(() => {
    toast.info(getSetupReminderMessage(),{
      toastId: 'integration-page-info',
  }); }, []);

  return <Navigate to="/integration-page" replace />;
};
const PrivateRoute = ({ children, isAdminRoute }) => {
  const token = getStoredValue("token");
  const isSuperAdmin = getStoredValue("is_super_admin");
  const location = useLocation();
  const [authStatus, setAuthStatus] = useState('checking');
  const isLoggedIn = getStoredValue("isLoggedIn");
  const store_id = getStoredValue("storeid");
  const documentUploaded = getStoredValue("documentUploaded") === "true";
  const onboarded = getStoredValue("isonboarded") === "true";
  const emailaccount = getStoredValue("emailaccount") === "true";
  const shopifyaccount = getStoredValue("shopifyaccount") === "true";
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const isAuth = await isAuthenticated();
        setAuthStatus(isAuth ? "authenticated" : "unauthenticated");
      } catch (error) {
        console.error("Authentication error:", error);
        setAuthStatus("unauthenticated");
      }
    };

    checkAuth();
  }, []);
  const getSetupReminderMessage = () => {
    const incompleteSteps = [];
    if (!emailaccount) incompleteSteps.push("an Email Account");
    if (!shopifyaccount) incompleteSteps.push("a Shopify Store");
    if (incompleteSteps.length > 0) {
      return `To proceed, please link ${incompleteSteps.join(" and ")}. This step is necessary for draft generation..`;
    }
  };

  if (authStatus === "checking") {
    return (<div className="flex items-center justify-center h-screen"><RotatingLoader /></div>);
  }

  if (authStatus === "unauthenticated" && isLoggedIn !== "true") {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  if (!token) {
    return <Navigate to="/signin" replace />;
  }
  
  if(isSuperAdmin === "True" && (store_id==="undefined")  ){
    return <Navigate to = "/admin/dashboard-page" replace />;
  }
  if (isSuperAdmin === "True" && store_id === "undefined") {
    return <Navigate to="/admin/dashboard-page" replace />;
  }

  if (isSuperAdmin === "False") {
    // Redirect to welcome page if user has not completed onboarding
    if (!onboarded && !location.pathname.includes("/welcome-page")) {
      return <Navigate to="/welcome-page" replace />;
    }

    // Redirect users on the welcome page to the document page if onboarding is completed
    if (onboarded && location.pathname.includes("/welcome-page")) {
      return <Navigate to="/document-page" replace />;
    }

    // Check integration completion only if not in OAuth flow
    if (!window.isOAuthFlow && (!emailaccount || !shopifyaccount) && onboarded) {
      if (!location.pathname.includes("/integration-page")) {
        return <RedirectToIntegrationPage getSetupReminderMessage={getSetupReminderMessage} />;
      }
    }

    if (!documentUploaded && onboarded) {
      if (!location.pathname.includes("/document-page")) {
        return <RedirectToDocumentPage />;
      }
    }
    // Redirect from root path to the document page if no other redirects apply
    if (location.pathname === "/") {
      return <Navigate to="/document-page" replace />;
    }
    return <Outlet />;
  }

  if (isSuperAdmin === "True" && store_id !== "undefined") {
    if (location.pathname === "/") {
      return <Navigate to="/document-page" replace />;
    }
    return <Outlet />;
  }
  
 
  return <Navigate to="/error" replace />;
  
  

};

export default PrivateRoute;
