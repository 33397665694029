import { useState } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EmailSignup } from "../../services/login";
import WhiteLoadingAnimation from "../../assets/WhiteLoadingAnimation.svg";

function SignupForm() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
    });
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const { firstName, lastName, email, password, confirmPassword } = formData;

    // Handle input fields when values change
    const handleOnChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };

    // Handle Form Submission
    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (!firstName || !lastName) {
            toast.error("First name and Last name are required");
            return;
        }

        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        try {
            setLoading(true); // Start the loading indicator

            // Call EmailSignup function
            const response = await EmailSignup(email, password, confirmPassword, firstName, lastName);

            // Show success message and navigate to another page
            toast.success(response.message || "Signup successful. Please verify your email.");

            // Reset form data
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
            });
        } catch (error) {
            // Display a more specific error message if available, fallback to generic
            const errorMessage = error.response?.data?.detail || "Something went wrong. Please try again.";
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };



    return (
        <>
            {/* Form */}
            <form onSubmit={handleOnSubmit} className="mt-3 flex w-full flex-col gap-y-4 font-dmsans">
                {/* First Name and Last Name */}
                <div className="flex gap-x-4">
                    <label className="w-1/2 space-y-2">
                        <p className="text-[14px] font-normal text-[#DBDBDB]">First name</p>
                        <input
                            required
                            type="text"
                            name="firstName"
                            value={firstName}
                            onChange={handleOnChange}
                            placeholder="Enter first name"
                            autoComplete="off"
                            className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px] text-[18px]"
                        />
                    </label>
                    <label className="w-1/2 space-y-2">
                        <p className="text-[14px] font-normal text-[#DBDBDB]">Last name</p>
                        <input
                            required
                            type="text"
                            name="lastName"
                            value={lastName}
                            onChange={handleOnChange}
                            placeholder="Enter last name"
                            autoComplete="off"
                            className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px] text-[18px]"
                        />
                    </label>
                </div>

                {/* Email */}
                <label className="w-full space-y-2">
                    <p className="text-[14px] font-normal text-[#DBDBDB]">Email</p>
                    <input
                        required
                        type="text"
                        name="email"
                        value={email}
                        onChange={handleOnChange}
                        placeholder="Enter email address"
                        autoComplete="off"
                        className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px] text-[18px]"
                    />
                </label>

                {/* Password */}
                <label className="relative space-y-2">
                    <p className="text-[14px] font-normal text-[#DBDBDB]">Password</p>
                    <div className="relative">
                        <input
                            required
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={password}
                            onChange={handleOnChange}
                            placeholder="Enter password"
                            autoComplete="off"
                            className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] !pr-12 font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px] text-[18px]"
                        />
                        <span
                            onClick={() => setShowPassword((prev) => !prev)}
                            className="absolute top-1/2 transform -translate-y-1/2 right-3 z-[10] cursor-pointer text-[#A9A9A9]"
                        >
                            {!showPassword ? (
                                <LuEye fontSize={24} /> // Eye icon for showing the password
                            ) : (
                                <LuEyeOff fontSize={24} /> // EyeOff icon for hiding the password
                            )}
                        </span>
                    </div>
                </label>

                {/* Confirm password */}
                <label className="relative space-y-2">
                    <p className="text-[14px] font-normal text-[#DBDBDB]">Confirm password</p>
                    <div className="relative">
                        <input
                            required
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={handleOnChange}
                            placeholder="Confirm password"
                            autoComplete="off"
                            className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] !pr-12 font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px] text-[18px]"
                        />
                        <span
                            onClick={() => setShowConfirmPassword((prev) => !prev)}
                            className="absolute top-1/2 transform -translate-y-1/2 right-3 z-[10] cursor-pointer text-[#A9A9A9]"
                        >
                            {!showConfirmPassword ? (
                                <LuEye fontSize={24} /> // Eye icon for showing the password
                            ) : (
                                <LuEyeOff fontSize={24} /> // EyeOff icon for hiding the password
                            )}
                        </span>
                    </div>
                </label>

                {/* Submit Button */}
                <button
                    type="submit"
                    className="mt-2 rounded-[6px] bg-[#39F6A3] w-full h-[50px] font-semibold text-[18px] text-[#1B1A22] flex justify-center items-center"
                    disabled={loading}
                >
                    {loading ? (
                        <span className="flex justify-center items-center h-full w-12 lg:w-14">
                            <img src={WhiteLoadingAnimation} alt="Loading..." />
                        </span>
                    ) : (
                        "Sign up"
                    )}
                </button>

            </form>
        </>
    );
}

export default SignupForm;
