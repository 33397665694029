import { useState, useEffect } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { BiArrowBack } from "react-icons/bi";
import Template from "../../components/SignInPageComponents/Template";
import { Link, useNavigate } from "react-router-dom";
import FileUploadedTickIcon from "../../assets/documentPageAssets/FileUploadedTickIcon.svg"
import { toast } from "react-toastify";
import axios from "axios";

function ResetPassword() {
    const navigate = useNavigate();
    const [token, setToken] = useState("");
    const API_URL = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: "",
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isResetSuccessful, setIsResetSuccessful] = useState(false); // Track reset success

    const { password, confirmPassword } = formData;

    // Get the token from URL parameters
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tokenFromURL = urlParams.get("token");
        if (tokenFromURL) {
            setToken(tokenFromURL);
        }
    }, [navigate]);

    const handleOnChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };


    const handleOnSubmit = async (e) => {
        e.preventDefault();
    
        if (password !== confirmPassword) {
            toast.error("Passwords do not match!");
            return;
        }
    
        try {
            setLoading(true);
    
            // Create FormData object
            const formData = new FormData();
            formData.append("reset_token", token); // Add reset token
            formData.append("new_password", password); // Add new password
            formData.append("confirm_password", confirmPassword); // Add confirm password
    
            // Make API request
            const response = await axios.post(`${API_URL}/v1/auth/reset-password`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setIsResetSuccessful(true);
            toast.success(response.data.message || "Password updated successfully!");
        } catch (error) {
            toast.error(error.response?.data?.detail || "Failed to reset password.");
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <Template>
            <div className={`flex flex-col justify-center ${!isResetSuccessful?'items-start':'items-center'}  lg:w-[418px] mt-4 mx-auto space-y-8 font-dmsans`}>
                {!isResetSuccessful ? (
                    <>
                        <h1 className="font-semibold text-[#FFFFFF] lg:text-[30px] ">
                            Reset password
                        </h1>
                        <form onSubmit={handleOnSubmit} className="w-full space-y-6">
                            <label className="w-full space-y-2">
                                <p className="text-[14px] font-normal text-[#DBDBDB]">
                                    New password
                                </p>
                                <div className="relative">
                                    <input
                                        required
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        value={password}
                                        onChange={handleOnChange}
                                        placeholder="Enter password"
                                        autoComplete="off"
                                        className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] text-[18px] font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px]"
                                    />
                                    <span
                                        onClick={() => setShowPassword((prev) => !prev)}
                                        className="absolute top-1/2 transform -translate-y-1/2 right-3 z-[10] cursor-pointer text-[#A9A9A9]"
                                    >
                                        {!showPassword ? (
                                            <LuEye fontSize={24} />
                                        ) : (
                                            <LuEyeOff fontSize={24} />
                                        )}
                                    </span>
                                </div>
                            </label>

                            <label className="w-full space-y-2">
                                <p className="text-[14px] font-normal text-[#DBDBDB]">
                                    Confirm password
                                </p>
                                <div className="relative">
                                    <input
                                        required
                                        type={showConfirmPassword ? "text" : "password"}
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={handleOnChange}
                                        placeholder="Confirm password"
                                        autoComplete="off"
                                        className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] text-[18px] font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px]"
                                    />
                                    <span
                                        onClick={() => setShowConfirmPassword((prev) => !prev)}
                                        className="absolute top-1/2 transform -translate-y-1/2 right-3 z-[10] cursor-pointer text-[#A9A9A9]"
                                    >
                                        {!showConfirmPassword ? (
                                            <LuEye fontSize={24} />
                                        ) : (
                                            <LuEyeOff fontSize={24} />
                                        )}
                                    </span>
                                </div>
                            </label>

                            <button
                                type="submit"
                                className="w-full rounded-[6px] bg-[#F3F3F3] h-[50px] text-[18px] text-[#1B1A22] font-semibold"
                            >
                                Reset password
                            </button>
                        </form>
                    </>
                ) : (
                    <div className="flex flex-col justify-center items-center">
                        <h1 className="font-semibold text-[#FFFFFF] lg:text-[30px] mb-4">
                            Password updated!
                        </h1>
                        <img
                            src={FileUploadedTickIcon}
                            alt="Success"
                            className="w-60 h-50 object-contain"
                        />
                    </div>
                )}

                <div className="flex items-center justify-between text-[#DBDBDB] text-[18px] font-dmsans mt-3">
                    <Link to="/signin">
                        <p className="flex items-center gap-x-2">
                            <BiArrowBack />Login
                        </p>
                    </Link>
                </div>
            </div>
        </Template>
    );
}

export default ResetPassword;
