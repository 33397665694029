import { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import Template from "../../components/SignInPageComponents/Template";
import LoadingAnimation from "../../assets/LoadingAnimation.svg";
import VerifyEmailImage from "../../assets/AuthPagesAssets/VerifyEmail.svg"
import { toast } from "react-toastify";
import { verifyEmailAPI } from "../../services/login";

function VerifyEmail() {
    const [email, setEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [emailVerified, setEmailVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Retrieve code from query parameters
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        if (code) {
            setVerificationCode(code);
        }
    }, [navigate]);

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (!email || !verificationCode) {
            toast.error("Both email and verification code are required.");
            return;
        }

        try {
            setLoading(true);

            // Call the API
            const response = await verifyEmailAPI(email, verificationCode);

            // Handle success
            setEmailVerified(true);
            toast.success(response.message || "Email verified successfully!");
        } catch (error) {
            toast.error(`${error.response?.data?.detail}`);
        } finally {
            setLoading(false);
        }
    };


    return (
        <Template>
            <div className="flex flex-col justify-center items-start  lg:w-[418px] mt-4 mx-auto space-y-2 font-dmsans">

                {emailVerified && (
                    <div className="flex justify-center items-center my-4 w-full">
                        <img src={VerifyEmailImage} alt="Verified" className="h-40 w-50 object-contain" />
                    </div>
                )}
                <h1 className="font-semibold text-[#FFFFFF] lg:text-[30px]">
                    {!emailVerified ? "Verify your email" : "Email verified!"}
                </h1>
                <p className="text-[18px] text-[#ABABAB] font-light">
                    {!emailVerified
                        ? "Enter your email address"
                        : `Your email ${email} has been successfully verified.`}
                </p>

                <form onSubmit={handleOnSubmit} className="w-full space-y-4">
                    {!emailVerified && (
                        <>
                            {/* Email Input */}
                            <label className="my-2 w-full">
                                <p className="text-[14px] font-normal text-[#DBDBDB]">Email</p>
                                <input
                                    required
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter email address"
                                    autoComplete="off"
                                    className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] text-[18px] font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px] mt-1"
                                />
                            </label>
                            <button
                                type="submit"
                                disabled={loading}
                                className="w-full rounded-[6px] h-[50px] text-[18px] font-semibold bg-[#F3F3F3] text-[#1B1A22] flex justify-center items-center"
                            >
                                {loading ? (
                                    <span className="flex justify-center items-center h-full w-12 lg:w-14">
                                        <img src={LoadingAnimation} alt="Loading..." />
                                    </span>
                                ) : (
                                    "Verify email"
                                )}
                            </button>
                        </>
                    )}

                </form>
                <div className="flex items-center justify-between text-[#DBDBDB] text-[18px] font-dmsans mt-3">
                    <Link to="/signin">
                        <p className="flex items-center gap-x-2">
                            <BiArrowBack /> Login
                        </p>
                    </Link>
                </div>
            </div>
        </Template>
    );
}

export default VerifyEmail;
