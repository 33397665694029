import React from 'react';
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import { useLocation} from "react-router-dom";
const DraftsPagination = ({ currentPage, totalDrafts, onPageChange }) => {
  const location=useLocation();
  const isDocumentPage=location.pathname === "/document-page";
  const totalPages = Math.ceil(totalDrafts / (isDocumentPage? 5:10));
  const searchParams = new URLSearchParams(location.search);
  const filter = searchParams.get("filter");

  const generatePageNumbers = () => {
    if (totalPages <= 5) {
      return [...Array(totalPages)].map((_, i) => i + 1);
    }

    if (currentPage <= 3) {
      return [1, 2, 3, 4, '...', totalPages];
    }

    if (currentPage >= totalPages - 2) {
      return [1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    }

    return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
  };

  const handlePageChange = (page) => {
    if (page === '...') return;
    onPageChange(page);
  };

  const getPageTitle = (pathname) => {
    switch (true) {
      case pathname.includes("document-page"):
        return "policies";
      case pathname.includes("examples-page"):
        return "examples";
      case pathname.includes("emails-page"): {
        if (filter) {
          switch (filter) {
            case "drafts":
              return "drafts";
            case "all":
              return "emails";
            case "sent":
              return "emails";

            default:
              return "drafts";
          }
        }
        return "drafts";
      }
      case pathname.includes("/admin/store-page"):
        return "stores";
      case pathname.includes("/admin/dashboard-page"):
        return "users";


    }
  };
  return (
    <div
      className="flex flex-col items-center sm:py-2 font-dmsans sm:flex-row sm:justify-between">
      <span className="text-[#868CA2] font-medium text-[10px] sm:text-sm font-dmsans sm:pl-1 mb-2 sm:mb-0">
        <span className="mr-1">{Math.min(currentPage * (isDocumentPage ? 5 : 10), totalDrafts)}</span>
        <span className="mr-1">of</span>
        <span>{totalDrafts} {getPageTitle(location.pathname)}</span>
      </span>
      <div className="flex items-center space-x-2">
        <button
          data-testid="pagination-left"
          className={`px-1 py-1 rounded-md ${currentPage === 1 ? 'text-[#868CA2] cursor-not-allowed' : 'text-[#000000]'}`}
          onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FaChevronLeft className="lg:w-4 lg:h-4 w-3 h-3" />
        </button>
        {generatePageNumbers().map((pageNumber, index) => (
          <button
            key={index}
            className={`rounded-sm sm:w-6 sm:h-6 w-5 h-5 sm:text-sm text-xs ${currentPage === pageNumber
                ? 'bg-[#B0FBDA] text-[#000000]'
                : 'text-[#868CA2]'
              }`}
            onClick={() => handlePageChange(pageNumber)}
            title="pagination-number"
          >
            {pageNumber}
          </button>
        ))}
        <button
          data-testid="pagination-right"
          className={`px-1 py-1 rounded-md ${currentPage === totalPages ? 'text-[#868CA2] cursor-not-allowed' : 'text-[#000000]'}`}
          onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <FaChevronRight className="lg:w-4 lg:h-4 w-3 h-3" />
        </button>
      </div>

    </div>
  );
};

export default DraftsPagination;
