import React from 'react';

const ListButtonIcon = ({ isActive }) => {
  return (
    <>
      {isActive ? (
        <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="3.86182" width="10.4" height="10.6213" rx="5.2" fill="#2B2E3A" />
          <rect x="4" y="3.86182" width="10.4" height="10.6213" rx="5.2" stroke="#464343" stroke-opacity="0.15" stroke-width="5.02" />
          <circle cx="8.4" cy="8.28944" r="2" fill="#39F6A3" />
        </svg>

      ) : (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="6" cy="6" r="4.5" fill="#76FFC5" stroke="#868CA2" strokeWidth="3" />
        </svg>
      )}
    </>
  );
};

export default ListButtonIcon;
