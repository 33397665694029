import React, { useState, useEffect,useRef } from "react";
import AddFileIconGreen from "../assets/documentPageAssets/AddFileIconGreen.svg";
import AssignmentIcon from "../assets/documentPageAssets/AssignmentIcon.svg";
import { formatCustomDateTime,getStoredValue } from "../services/logics";
import CheckCircle from "../assets/documentPageAssets/CheckCircle.svg";
import LoadingBar3 from "../assets/documentPageAssets/LoadingBar3.gif";
import BouncingGif from "../assets/documentPageAssets/bouncingGif.gif";
import { HiEllipsisVertical } from "react-icons/hi2";
import DocumentListShimmer from "../components/DocumentSkeleton.jsx";
import { toast } from "react-toastify";
import { useClickOutsideDropdown } from "../services/emailUtils.js";
import { useSelector,useDispatch } from 'react-redux';
import FeaturedIcon from "../assets/FeaturedIcon.svg";
import PopupModal from "../components/PopupModal";
import WhiteLoadingAnimation from "../assets/WhiteLoadingAnimation.svg";
import SucessModal from "../components/SucessModal.jsx";
import DeleteIcon from "../assets/documentPageAssets/DeleteIcon.jsx";
import DownloadIcon from "../assets/documentPageAssets/DownloadIcon.svg";
import DraftsPagination from "../components/DraftsPagination.jsx";
import { IoEyeOutline  } from "react-icons/io5";
import axiosInstance from "../services/axiosInstance.js";
import { isAuthenticated } from "../services/login.js";
import { setDocumentsTrue, setDocumentsFalse } from '../redux/notificationSlice.js';
import Emptydocuments from '../assets/documentPageAssets/Emptydocuments.svg'
import { useNavigate} from "react-router-dom";
const statusSvgGenerator = (color) => {
  const svgString = `
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
      <circle cx="3.5" cy="3.5" r="3.5" fill="${color}" />
    </svg>
  `;
  const base64EncodedSvg = btoa(unescape(encodeURIComponent(svgString)));
  const dataURI = `data:image/svg+xml;base64,${base64EncodedSvg}`;

  return dataURI;
};

const DocumentsPage = () => {
  const [file, setFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const is_super_admin = getStoredValue("is_super_admin")
  const storeid = getStoredValue("storeid")
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  const token = getStoredValue("token");
  const API_URL = process.env.REACT_APP_API_URL;
  const [isFetching, setIsFetching] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [disabledViewButtons, setDisabledViewButtons] = useState({});

  
  const [pageNum, setPageNum] = useState(1);
  const dropdownRef = useRef([]);  
  const [totalFiles, setTotalFiles] = useState(0);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null); // State for storing selected file ID
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const role = getStoredValue("role");
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [pageNum]);

  const fetchData = async () => {
    setIsFetching(true);
    try {
      const response = await axiosInstance.get(`${API_URL}/v1/store/documents?page=${pageNum}&page_size=5`, {
       
      });

      const data = response.data;

      const filteredData = data.documents.map(file => ({
        id: file.id,
        uploaded_on: file.uploaded_on,
        name: file.name,
        status: file.status,
        url: file.url,
        size: (file.size / 1024).toFixed(2) + " KB",
      }));
      setTotalFiles(data.total);
      handleToggleNotification(data.total);
      setUploadedFiles(filteredData);
      setIsFetching(false);
      await isAuthenticated();
    } catch (error) {
      toast.error(error.response?.data?.detail);
      setIsFetching(false);
    }
  };

  const handleToggleNotification = (totalFiles) => {
    if (totalFiles > 0) {
      dispatch(setDocumentsFalse()); 
    } else if (totalFiles <= 0) {
      dispatch(setDocumentsTrue());  
    }
  };

  const handleCloseModal = () => {
    setFile(null);
  };
  const handleCloseDownloadModal = () => {
    setShowDownloadModal(false)
  }
  const handleDelete = () => {
    setShowDeleteModal(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e, type) => {

    e.preventDefault();
    setIsDragOver(false);
    let selectedFile;
    if (type === "drop") {
      selectedFile = e.dataTransfer.files[0];
    } else {
      selectedFile = e.target.files[0];
    }

    // Validate file format
    if (selectedFile && selectedFile.type !== 'application/pdf') {
      setErrorMessage('*Only .pdf files are allowed.');
      setFile(null);
      return;
    } else {
      // If valid, proceed with the upload
      setFile(selectedFile);
      setErrorMessage(''); // Clear any existing error message

      // Start the upload
      startUpload(selectedFile);
    }

  };


  function convertDateReverse(dateString) {
    const originalFormat = formatCustomDateTime(dateString);
    const [month, day, year] = originalFormat.replace(',', '').split(' ');
    return `${day} ${month} ${year}`; // Reverse the day and month without commas
  }

  const startUpload = async (uploadedFile) => {
    const maxSizeInBytes = 15 * 1024 * 1024;
  
    if (uploadedFile.size > maxSizeInBytes) {
      setFile(null);
      toast.error("File size exceeds the limit of 10 MB.");
      return;
    }
  
    if (!uploadedFile) return;
  
    const formData = new FormData();
    formData.append("file", uploadedFile);
  
    try {
      setIsUploading(true);
      const response = await axiosInstance.post(
        `${API_URL}/v1/store/documents`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      const data = response.data;
  
      // Add the new document to the list
      setUploadedFiles([
        ...uploadedFiles,
        {
          id: data.id,
          name: data.name,
          status: "Processing",
          uploaded_on: data.uploaded_on,
          url: data.url,
        },
      ]);
  
      // Disable View button for 10 seconds
      setDisabledViewButtons((prevState) => ({
        ...prevState,
        [data.id]: true,
      }));
      setTimeout(() => {
        setDisabledViewButtons((prevState) => ({
          ...prevState,
          [data.id]: false,
        }));
      }, 10000);
  
      setTotalFiles(totalFiles + 1);
      setIsUploading(false);
      await isAuthenticated();
    } catch (error) {
      toast.error(error.response?.data?.detail);
      setIsUploading(false);
    }
  };
  

      
  const handlePageChange = (page) => {
    setPageNum(page);
  }

  const handleDeleteDocument = async () => {
    setIsDeleting(true);
    try {
      const res = await axiosInstance.delete(`${API_URL}/v1/store/documents/${selectedFileId}`);
      const data = res.data;
      toast.success('Deleted Successfully');
      // Fetch the updated list of files after deletion
      const updatedFiles = uploadedFiles.filter(file => file.id !== selectedFileId);
      setUploadedFiles(updatedFiles);
  
      // If the current page becomes empty after deletion, move to the previous page
      if (updatedFiles.length === 0 && pageNum > 1) {
        setPageNum(prevPage => prevPage - 1);
      } else {
        fetchData(); // If there are still documents left, simply refresh the current page
      }
      setShowDeleteModal(false);
      setIsDeleting(false);
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
      setIsDeleting(false);
    }

  };
  const handleDownloadClick = (doc) => {
    setSelectedFile(doc); // Store the file details
    setShowDownloadModal(true); // Show the modal
  };

  // Function to initiate the download inside the modal
  const handleConfirmDownload = () => {
    if (selectedFile) {
      const link = document.createElement('a');
      link.href = selectedFile.url;
      link.download = selectedFile.name;
      link.click();
    }
    setShowDownloadModal(false);
  };
  const handleDropdownToggle = (fileId) => {
    setActiveDropdown(prev => prev === fileId ? null : fileId); // Toggle dropdown for the specific file
  };

  useClickOutsideDropdown(dropdownRef, setActiveDropdown, true);

  const handleViewClick = (doc) => {
    if (doc?.url) {
      const timestamp = new Date().getTime();
      const googleViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(doc.url)}&embedded=true&nocache=${timestamp}`;
      window.open(googleViewerUrl, '_blank', 'noopener,noreferrer');
    } else {
      console.error('Document URL is not available');
    }
  };


  return (
    <div
      className={`flex h-[calc(100vh-80px)] bg-[#FFFFFF] lg:bg-[#F9F9FB]  flex-col items-center ${isSidebarOpen ? 'lg:ml-[220px]  xl:ml-[240px]' : 'lg:ml-[80px]'} transition-all duration-300  py-2`}
    >
      {location.pathname.includes("document") && <div className="font-dmsans font-medium text-[#000000] w-full pl-4 sm:pl-7 xl:text-[20px] mb-3">Policies</div>}
      
      <div
  className={`bg-[#EEFDF5] lg:mb-8 h-autolg:h-[340px] rounded-lg shadow-md p-6 border-[1px] border-[#D7FDED] border-opacity-50 w-[94%] md:w-[688px]`}
  onDragOver={handleDragOver}
  onDragLeave={handleDragLeave}
  onDrop={(e) => handleDrop(e, "drop")}
>
  <div className="flex items-center justify-center mb-4">
    <span
      className={`border-[1px] bg-secondary-400 border-opacity-50 text-white rounded-full p-[16px] lg:p-[22px] ${isUploading && "hidden"} ${file ? "" : ""}`}
    >
      <img src={file ? CheckCircle : AddFileIconGreen} alt="Add File Icon" />
    </span>
    {isUploading && (
      <img src={BouncingGif} alt="Uploading Animation" className={`${isUploading && "h-20 w-20"}`} />
    )}
  </div>
  <h2
    className={`lg:text-xl text-sm text-secondary-400 leading-normal font-dmsans font-bold text-center mb-4 ${
      file ? "mb-4" : ""
    }`}
  >
    {isUploading
      ? "Uploading your document..."
      : file
      ? "Successfully uploaded"
      : "Add Manually or upload your Store Policy"}
  </h2>
 

  {isUploading ? (
    <div className="flex flex-col mb-2 w-full justify-center items-center">
      <img src={LoadingBar3} alt="Loading..." className="w-64 h-[10px] p-0 m-0" />
      <div className="text-center text-[#868CA2] font-dmsans mt-3 text-xs">
        Please wait while we upload your document
      </div>
    </div>
  ) : (
    <div className="flex flex-col items-center gap-3">
      <input
        type="file"
        accept=".pdf"
        value=""
        onChange={(e) => handleDrop(e, "input-file")}
        className="hidden"
        id="fileInput"
      />
      <label
        htmlFor="fileInput"
        className="sm:w-[282px] sm:h-[48px] bg-[#96F3C3] rounded-[10px] text-[#000000] w-[90%] text-center py-2 font-medium font-dmsans text-sm lg:text-base cursor-pointer"
      >
        Upload Document
      </label>
      <p
    className={`text-secondary-400 text-opacity-50 font-dmsans text-sm lg:text-base font-medium mb-1 text-center ${
      file ? "hidden" : ""
    }`}
  >
    Only PDF files are accepted, with a maximum file size of 10 MB.
  </p>

      {/* Add Manually Button */}
      <button
        className="sm:w-[282px] sm:h-[48px] bg-white border-[2px] border-black rounded-[10px] text-[#000000] w-[90%] text-center py-2 font-medium font-dmsans text-sm lg:text-base"
        onClick={() => navigate('/document-page/create-policy')}
      >
        Add Manually
      </button>

      {/* Download Template Section */}
      <div className="flex items-center justify-center gap-2">
        <img
          src={DownloadIcon}
          alt="Download Template Icon"
          className="w-5 h-5"
        />
        <button
          className="text-[#000000] underline font-medium font-dmsans text-sm lg:text-base"
          onClick={() => {
            const link = document.createElement('a');
            link.href = '/path/to/template.pdf'; // Replace with your actual template file path
            link.download = 'template.pdf';
            link.click();
          }}
        >
          Download template
        </button>
      </div>
    </div>
  )}

  {errorMessage && <p className="text-red-500 text-xs text-center mt-4">{errorMessage}</p>}
</div>






      <div className="mx-auto w-[94%] md:w-full pb-[20px] relative lg:bg-[#F9F9FB]">
        {/* {uploadedFiles?.length > 0 && <h2 className="text-[#000000] text-sm lg:text-base font-medium  sm:mb-4 mt-6 md:text-left md:mx-4 xl:mx-8">
          Uploaded Document
        </h2>} */}
        <div className="lg:overflow-x-auto   md:mx-4  xl:mx-8 mb-2">
          {uploadedFiles?.length > 0 && <div className="hidden lg:flex items-center justify-between xl:px-6 py-2 bg-[#FEFEFE] h-[50px] text-[#2B2E3A] font-medium text-sm border-b border-gray-200">
            <div className="xl:w-[100px] lg:w-[90px] text-center">Date</div>
            <div className="flex-1 text-center">Name</div>
            <div className="xl:w-[140px] lg:w-[64px]">Status</div>
            <div className="xl:w-[110px] lg:w-[140px] text-center">Download</div>
            <div className="xl:w-[110px] lg:w-[60px] text-center">View</div>
            <div className="xl:w-[100px] lg:w-[100px] text-center">Delete</div>
          </div>}
          {isFetching ? (
            <DocumentListShimmer items={5} />
          ) : uploadedFiles.length === 0 && !isUploading ? (
              <div className="flex flex-col justify-center items-center mt-10">
                <img
                  src={Emptydocuments}
                  alt=""
                // className={`${isUploading && "h-20 w-20"}`}
                />
                <div className="text-[#000000] font-dmsans text-xs sm:text-base  mt-2">Upload your first document to get started!</div>
              </div>
            ) : (uploadedFiles.map((doc, index) => (
              <div
                key={index}
                className="flex items-center justify-between md:px-2 xl:px-4 py-4 bg-[#FFFFFF] lg:bg-[#FEFEFE] h-[60px]"
              >
                {/* Date */}
                <div className="flex items-center text-xs xl:text-sm text-[#2B2E3A] font-medium w-[120px] xl:w-[150px]  sm:mr-4">
                  {convertDateReverse(doc.uploaded_on)}
                </div>

                {/* Document Name */}
                <div className="flex flex-1 items-center gap-1 lg:gap-2 text-xs xl:text-sm font-medium text-[#2B2E3A] truncate">
                  <img src={AssignmentIcon} alt="file icon" className="md:h-5 md:w-5 h-4 w-4" />
                  <span className="truncate">
                    {doc.name}
                  </span>
                </div>

                {/* File Size and Action Buttons */}
                  <div className={`hidden md:flex md:ml-[10px] lg:ml-[20px] xl:ml-[60px] justify-start items-center xl:w-[150px] lg:w-[90px] md:w-[140px]`}>
                    <img
                      className="h-fit"
                      src={statusSvgGenerator(
                        `${doc.status === "Ready"
                          ? "#1BFF4D"
                          : doc.status === "Processing"
                            ? "#FFE500"
                            : "#FF2D2D"
                        }`
                      )}
                      alt=""
                    />
                    <span className={`font-dmsans px-2 py-1 rounded-full text-[14px] lg:text-base`}>
                      {doc.status}
                    </span>
                  </div>

                  <div className={`hidden lg:flex items-center lg:w-[120px] xl:w-[120px] justify-center`}>
                    {/* Download Button */}
                  <button
                    className="text-[#000000] hover:bg-gray-100 rounded-md bg-[#F6F6F633] border border-[#868CA21A] lg:w-[25px] lg:h-[25px] xl:w-[30px] xl:h-[30px] flex items-center justify-center"
                    onClick={() => handleDownloadClick(doc)}
                  >
                    <img
                      src={DownloadIcon}
                      alt="Download"
                      className="xl:w-[22px] xl:h-[22px] lg:w-[20px] lg:h-[20px]"
                      data-testid='download'
                    />
                  </button>
                  </div>

                {/* View */}
                <div className="hidden lg:flex items-center justify-center lg:w-[80px] xl:w-[100px] ">
                  <button
                    className="text-[#000000] hover:bg-gray-100 rounded-md bg-[#F6F6F633] border border-[#868CA21A] flex items-center justify-center lg:w-[25px] lg:h-[25px] xl:w-[30px] xl:h-[30px]"
                    onClick={() => handleViewClick(doc)}
                    disabled={disabledViewButtons[doc.id]}
                  >
                    <IoEyeOutline className="xl:w-[22px] xl:h-[22px] lg:w-[20px] lg:h-[20px]" /> {/* Adjusted icon spacing */}
                  </button>
                </div>

                <div className={`hidden lg:flex items-center lg:w-[80px] xl:w-[110px] justify-center`}>
                  {/* Delete Button */}

                  <button className={`text-[#000000] hover:bg-gray-100 rounded-md bg-[#F6F6F633] border border-[#868CA21A] flex items-center justify-center lg:w-[25px] lg:h-[25px] xl:w-[30px] xl:h-[30px] ${role === 'admin' ? '' : ''}`} onClick={() => {
                    setShowDeleteModal(true); setSelectedFileId(doc.id);
                  }} data-testid="delete">
                    <DeleteIcon className="xl:w-[22px] xl:h-[22px] lg:w-[20px] lg:h-[20px]" />
                  </button>
                </div>

                  {/* Three Dots Button for small screens */}
                <div className="lg:hidden relative" ref={(el) => (dropdownRef.current[index] = el)}>
                  <button
                    className="text-[#727272] hover:text-gray-700 bg-[#FFFFFF]  flex items-center justify-center"
                    onClick={() => handleDropdownToggle(doc.id)} // Toggle dropdown for this file
                  >
                    <HiEllipsisVertical className="w-[22px] h-[22px]" />
                  </button>

                  {/* Dropdown for Mobile */}
                  {activeDropdown === doc.id && (
                    <div className="absolute z-10 right-0 mt-2 w-[120px] text-xs bg-white shadow-lg rounded-md border border-gray-200">
                      <button
                        className="block w-full text-left px-2 py-2 text-gray-700 hover:bg-gray-100"
                        onClick={() => handleDownloadClick(doc)}
                      >
                        Download
                      </button>
                      <button
                        className="block w-full text-left px-2 py-2 text-gray-700 hover:bg-gray-100"
                        onClick={() => {
                          setShowDeleteModal(true);
                          setSelectedFileId(doc.id);
                        }}
                      >
                        Delete
                      </button>
                      <button
                        className="block w-full text-left px-2 py-2 text-gray-700 hover:bg-gray-100"
                        onClick={() => handleViewClick(doc)}
                      >
                        View
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
          {isUploading && (
            <div>
              <DocumentListShimmer items={1} />
            </div>
          )}
        </div>
        {uploadedFiles.length >0 &&<div className="md:mx-4 xl:mx-8"> {/* Adjust this container to align pagination to the left */}
          <DraftsPagination
            currentPage={pageNum}
            totalDrafts={totalFiles}
            onPageChange={handlePageChange}
          />
        </div>}
      </div>

      {file && !isUploading && (
        <SucessModal text={'Document uploaded successfully'} handleModal={handleCloseModal}/>
        
      )}

      {/* Show delete Modal */}
      {showDeleteModal && (
        <PopupModal closeModal={handleDelete} className="bg-[#F9F9F9]">
          <div className="flex flex-col justify-center items-center w-[300px] sm:h-[200px] sm:w-[360px]">
            <img src={FeaturedIcon} alt="" className="mb-2" />
            <div className="text-[#101828] font-medium text-base sm:text-lg mb-2">Delete document</div>
            <p className="text-[#667085] font-dmsans text-sm sm:text-[15px] font-normal mb-4 text-center">
            Are you sure you want to delete this <br/> document? This action cannot be undone.
            </p>
            <div className="flex justify-center space-x-6 w-full">
              <button
                className="text-sm sm:text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[36px] sm:h-[39px] flex items-center justify-center"
                onClick={handleDelete}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleDeleteDocument}
                disabled={isDeleting}
                className="w-[150px] h-[36px] sm:h-[39px] rounded-md bg-[#D92D20] text-sm sm:text-base font-medium text-[#FFFFFF] text-center flex items-center justify-center"
              >
                {isDeleting ? (
                  <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </PopupModal>
      )}

      {/* Download Confirmation Modal */}
      {showDownloadModal && (
        <PopupModal closeModal={handleCloseDownloadModal} className="bg-[#F9F9F9]">
          <div className="flex flex-col justify-center items-center w-[300px] sm:h-[200px] sm:w-[360px]">
            <div className="flex flex-col items-center space-y-7">
              <img
                src={DownloadIcon}
                alt="Download"
                className="sm:w-[44px] sm:h-[44px] w-[40px] h-[40px]"
                style={{
                  filter: 'invert(73%) sepia(84%) saturate(336%) hue-rotate(82deg) brightness(97%) contrast(97%)'
                }}
              />
              <p className="text-base sm:text-lg font-medium  text-center">Do you want to download this file?</p>
              <div className="flex space-x-4">
                <button
                  className="text-sm sm:text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[40px] sm:h-[44px] flex items-center justify-center"
                  onClick={handleCloseDownloadModal}
                >
                  Cancel
                </button>
                <button
                  className="px-3 w-[150px] h-[40px] sm:h-[44px] rounded-md  font-dmsans bg-[#96F3C3] text-sm sm:text-base text-[#000000] font-medium flex items-center justify-center"
                  onClick={handleConfirmDownload}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </PopupModal>

      )}
    </div>
  );
};

export default DocumentsPage;
