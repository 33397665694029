import React from 'react';

const DocumentListShimmer = ({ items, show = true }) => {
  return show ? (
    <div className="space-y-2 mt-2">
      {[...Array(items)].map((_, index) => (
        <div
          key={index}
          className="flex flex-row items-center justify-between p-2 rounded-md border border-gray-200 animate-pulse bg-white h-[60px] md:h-[50px] overflow-hidden"
        >
          {/* Left Section */}
          <div className="flex items-center space-x-4 sm:space-x-6 md:space-x-8 lg:space-x-14 xl:space-x-20 w-full lg:w-auto md:ml-2">
            <div className="h-4 w-16 md:w-20 lg:w-24 bg-gray-200 rounded"></div>
            <div className="h-4 w-28 md:w-36 lg:w-48 xl:w-56 bg-gray-200 rounded"></div>
          </div>

          {/* Right Section */}
          <div className="flex items-center justify-end w-full lg:w-auto mt-2 md:mt-0 sm:space-x-10 lg:space-x-20 xl:space-x-18 mr-5">
            <div className="h-4 w-12 hidden sm:block md:w-16 lg:w-20 bg-gray-200 rounded mr-4"></div>
            <div className="flex items-center sm:space-x-20 md:space-x-32 lg:space-x-24 xl:space-x-16">
              <div className="h-6 w-6 bg-gray-200 rounded hidden sm:block"></div>
              <div className="h-6 w-6 bg-gray-200 rounded"></div>
            </div>
            <div className="h-6 w-14 bg-gray-200 rounded hidden xl:block"></div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="flex justify-center w-full h-full">
     <div className="space-y-4 w-full lg:px-4"> {/* Adjusted space-y to 1 */}
  {[...Array(items)].map((_, idx) => (
    <div key={idx} className="flex justify-between items-center animate-pulse bg-white p-2 h-[50px] w-full">
      <div className="flex space-x-8 w-full"> {/* Adjusted space-x to 8 */}
        <div className="w-[50%] h-6 bg-gray-300 rounded lg:mr-20 mr-6"></div>
        <div className="w-1/6 h-6 bg-gray-300 rounded"></div>  
      </div>
      <div className="flex w-1/6 "> {/* Adjusted space-x to 1 */}
        <div className="h-6 bg-gray-300 rounded w-full"></div>
      </div>
    </div>
  ))}
</div>


    </div>



  );
};

export default DocumentListShimmer;


export const AccountSPageShimmer = ({ items }) => {
  return (
    <div className="space-y-3 mt-2 sm:mt-4">
      {[...Array(items)].map((_, idx) => (
        <div key={idx} className="flex items-center space-x-2 lg:space-x-4 py-2 w-full">
          {/* Image shimmer */}
          <div className="lg:w-10 lg:h-10 w-8 h-8 rounded-full bg-gray-300 animate-pulse"></div>

          {/* Text shimmer */}
          <div className="flex-1 lg:space-y-3 py-1 space-y-2">
            <div className="h-3 w-1/4 bg-gray-300 rounded animate-pulse"></div>
            <div className="h-3 w-[54%] bg-gray-300 rounded animate-pulse"></div>
          </div>

          {/* Status shimmer */}
          <div className="h-3 w-14 bg-gray-300 rounded animate-pulse"></div>
        </div>
      ))}
    </div>
  );
};

