import React from "react";

const PopupModal = ({ children, closeModal, className = "", outerClassName = "justify-center" }) => {
  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };
  return (
    <div
      className={`fixed inset-0 flex items-center backdrop-blur-sm transition duration-300 ${/\bz-\[?.*?\]?\b/.test(outerClassName) ? outerClassName : `z-50 ${outerClassName}`
        }`} onClick={handleOutsideClick}
    >
      <div className={`rounded-2xl shadow-lg p-6 transition duration-300 transform scale-100 opacity-100 bg-[#F3F3F3] ${className}`}>
        {children}
      </div>
    </div>
  );
};

export default PopupModal;
