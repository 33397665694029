import { createSlice } from '@reduxjs/toolkit';
import {BREAKPOINTS} from "../services/emailUtils"

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    isOpen: window.matchMedia(`${BREAKPOINTS.mobile}, ${BREAKPOINTS.mobileLarge}, ${BREAKPOINTS.tablet}`).matches ? false : true,
  },
  reducers: {
    toggleSidebar: (state) => {
      state.isOpen = !state.isOpen;
    },
    openSidebar: (state) => {
      state.isOpen = true;
    },
    closeSidebar: (state) => {
      state.isOpen = false;
    },
  },
});

export const { toggleSidebar, openSidebar, closeSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
