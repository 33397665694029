import React, { useEffect } from "react";
export const extractNameAndEmail = (str) => {
  const regex = /^(.*?)\s*<(.+?)>$/;
  const match = str?.match(regex);
  if (match) {
    return {
      name: match[1]?.trim(),
      email: match[2]?.trim(),
    };
  } else if (str?.includes('@')) { // Handle case where only email is provided
    return {
      name: str?.trim(),
      email: str?.trim(),
    };
  }
  return {
    name: 'Unknown Sender',
    email: '',
  };
};

// Helper function to get the value of 'giftcard' regardless of case and underscores
export const getGiftCardValue = (refundDetails) => {
  const giftcardKey = Object.keys(refundDetails || {}).find(
    key => key.replace(/_/g, '')?.toLowerCase() === 'giftcard'
  );
  return giftcardKey ? refundDetails[giftcardKey] : false;
};
// Helper function to get the value of 'issue' or 'solution' regardless of case and underscores
export const getDisputeValue = (disputeData, fieldName) => {
  const normalizedFieldName = fieldName.replace(/[_\s]/g, '')?.toLowerCase();
  const disputeKey = Object.keys(disputeData || {}).find(key =>
    key.replace(/[_\s]/g, '')?.toLowerCase() === normalizedFieldName
  );
  return disputeKey ? disputeData[disputeKey] : ''; // Return empty string if no match
};
// Function to convert ArrayBuffer to Base64
export const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const BREAKPOINTS = {
  mobile: '(max-width: 639px)',
  // Small (sm) - Tablets and larger mobile screens
  mobileLarge: '(min-width: 640px) and (max-width: 767px)',
  // Medium (md) - Larger tablets
  tablet: '(min-width: 768px) and (max-width: 1023px)',
  // Large (lg) - Laptops and small desktops
  laptop: '(min-width: 1024px) and (max-width: 1279px)',
  // Extra Large (xl) - Desktops and large screens
  desktop: '(min-width: 1280px) and (max-width: 1535px)',
  // Ultra Large (2xl) - Extra-large desktops or 4K monitors
  desktopLarge: '(min-width: 1536px)'
};

export function useClickOutsideDropdown(dropdownRef, setActiveDropdown, multiplerefs = false) {
  const handleClickOutside = (event) => {
    if (multiplerefs) {
      // Multiple refs case
      if (!dropdownRef.current.some((ref) => ref && ref.contains(event.target))) {
        setActiveDropdown(null); // Close all dropdowns
      }
    } else {
      // Single ref case
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null); // Close the dropdown
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setActiveDropdown, multiplerefs, dropdownRef]);
}


