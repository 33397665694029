import React, { useEffect } from 'react';

import { Outlet,useLocation } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Navbar from '../pages/Navbar';
import { useNetworkStatus } from "../services/logics";
import InternetConnectionIssue from "../components/InternetConnectionIssue";
const Layout = () => {
  const location = useLocation();
  const { isOnline, updateNetworkStatus } = useNetworkStatus();
  useEffect(() => {
    updateNetworkStatus();
  }, [location]);
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'isLoggedIn' && event.newValue === 'false') {
        // Log out this tab if isLoggedIn is set to false
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/signin';
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  const isWelcomePage = location.pathname.includes('/welcome-page');
  return (
    <><Navbar />
    <div className="flex">
      {!isWelcomePage && (<Sidebar />)}
      {isOnline ? (
      <div className="flex-3  w-full h-full">
        <Outlet />
      </div>):(<InternetConnectionIssue/>)}
    </div>
    </>
  );
};

export default Layout;