import React from "react";
import Template from "../../components/SignInPageComponents/Template";
const Signin = () => {
  return (
    <Template
      title="Login to your account"
      description="Or login with"
      formType="signin"
    />

  );
};

export default Signin;
